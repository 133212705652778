import { mapActions, mapGetters } from 'vuex';
import Calc from './Calc/index.vue';
import efficiency from './Efficiency/index.vue';
import i18n from '../i18n';

export default {
  computed: {
    ...mapGetters({
      expiredAutoDealer: 'cabinet/getExpiredAutoDealer',
    }),
  },
  methods: {
    ...mapActions({
      fetchEfficiencyData: 'cabinet/company/efficiency',
    }),
  },
  components: {
    'company-calc': Calc,
    efficiency,
  },
  i18n,
};
