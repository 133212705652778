import { mapGetters } from 'vuex';
import axios from 'axios';

export default {
  i18n: require('../i18n').default,
  data() {
    return {
      prices: {},
      inputSum: undefined,
      showClac: false,
    };
  },
  computed: {
    ...mapGetters({
      calculatorData_: 'cabinet/company/calculator',
      userInfo: 'cabinet/userInfo/userData',
    }),
    totalCalcSum() {
      let tmpSum = 0;
      for (const [key, value] of Object.entries(this.prices)) {
        tmpSum += Number(key) * Number(value);
      }
      return tmpSum;
    },

  },
  methods: {
    validate(grade) {
      if (this.prices[grade] < 0) {
        this.prices[grade] = 0;
      } else if (this.prices[grade] > 1000) {
        this.prices[grade] = 1000;
      }
    },
    createAccount(e) {
      e.preventDefault();

      const orderAmount = Number(this.showClac ? this.totalCalcSum : this.inputSum);
      if (orderAmount > 0) {
        const { id } = this.userInfo || {};

        if (id) {
          this.accountCreated = true;
          const billingWindow = window.open('https://auto.ria.com/demo/partials/loader/wait.html', '_blank', 'fullscreen=no,height=768,width=1024,toolbar=no,scrollbars=yes');
          axios({
            method: 'POST',
            url: '/api/discounts/public/create-refill-order/',
            data: {
              amount: orderAmount,
            },
          }).then((response) => {
            billingWindow.location.href = `https://oplaty.ria.com/auto/order/${this._result(response, 'data.orderId')}`;
          }).catch((error) => {
            console.error(`Company order error -->${JSON.stringify(error)}`);
            billingWindow.close();
          });
        } else {
          document.location = `${this.langPrefix}/login.html?from_url=${this.$route.fullPath}`;
        }
      }
    },
  },

  watch: {
    prices: {
      handler(newValue) {
        this.calculatorData_.grades.map((grades) => {
          if (newValue[grades.price] < 0) {
            newValue[grades.price] = 0;
          }
          if (newValue[grades.price] > 1000) {
            newValue[grades.price] = 1000;
          }
        });
      },
      deep: true,
    },
  },
};
