import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import sumBy from 'lodash/sumBy';
import i18n from '../i18n';
import statConverter from './statConverter';

export default {
  i18n,
  props: {
    userId: {
      type: String,
      default: null,
    },
    isAccountsSelect: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => ([]),
    },
    fetchData: Function,
  },
  data() {
    return {
      efficiencyData_: undefined,
      dateFrom: moment().subtract(30, 'day').format('YYYY-MM-DD'),
      dateTo: moment().format('YYYY-MM-DD'),
      costsForPeriod: [],
      statistic: [],
      sumBy,
    };
  },
  computed: {
    ...mapGetters({
      calculatorData_: 'cabinet/company/calculator',
    }),
  },
  beforeMount() {
    this.fetchCalculatorData();
    this.sendRequest();
  },
  methods: {
    ...mapActions({
      fetchCalculatorData: 'cabinet/company/calculator',
    }),
    sendRequest() {
      this.fetchData({
        from: this.dateFrom,
        to: this.dateTo,
        ...this.userId && { userId: this.userId },
      }).then((data) => {
        const convertedData = statConverter(data);
        this.costsForPeriod = convertedData.costsForPeriod;
        this.statistic = convertedData.statistic;
        this.efficiencyData_ = data;
      });
    },
    calculatorData(index) {
      const result = [];
      const grade = this.calculatorData_.grades[index];

      if (!grade.from && grade.to) {
        result.push('до', grade.to);
      } else if (grade.from && grade.to) {
        result.push(grade.from, '-', grade.to);
      } else {
        result.push(this.$t('от'), grade.from);
      }
      result.push(grade.dimension, grade.currencySign);

      return result.join(' ');
    },
  },
  watch: {
    dateFrom(newValue) {
      if (this.m(newValue) > this.m().subtract(1, 'days')) {
        this.dateFrom = this.m().subtract(1, 'days').format('YYYY-MM-DD');
      }

      if (this.m(newValue) < this.m().subtract(1, 'year')) {
        this.dateFrom = this.m().subtract(1, 'year').format('YYYY-MM-DD');
      }

      if (this.dateTo && this.m(newValue) > this.m(this.dateTo)) {
        this.dateFrom = this.m(this.dateTo).format('YYYY-MM-DD');
      }

      this.sendRequest();
    },
    dateTo(newValue) {
      if (this.m(newValue) > this.m().subtract(1, 'days')) {
        this.dateTo = this.m().subtract(1, 'days').format('YYYY-MM-DD');
      }

      if (this.dateFrom && this.m(newValue) < this.m(this.dateFrom)) {
        this.dateTo = this.m(this.dateFrom).format('YYYY-MM-DD');
      }

      this.sendRequest();
    },
    userId() {
      this.sendRequest();
    },
  },
};
