import get from 'lodash/get';

export default (data = []) => ({
  // складна структура з бека, прийшлось по кожному пункту індивідуально витягувати дані
  costsForPeriod: [
    {
      title: 'Публікації',
      count: Number(get(data, [0, 'content', 0, 'additionalRows', 0, 'value'], '').replace(/\s/g, '')),
      price: Number(get(data, [0, 'content', 0, 'mainRow', 'value'], '').replace(/\s/g, '')),
    },
    {
      title: 'ТОПи',
      count: Number(get(data, [0, 'content', 1, 'additionalRows', 0, 'value'], '').replace(/\s/g, '')),
      price: Number(get(data, [0, 'content', 1, 'mainRow', 'value'], '').replace(/\s/g, '')),
    },
    {
      title: '',
      titleNoTranslate: get(data, [0, 'content', 7, 'mainRow', 'grade', 0, 'description'], ''),
      count: Number(get(data, [0, 'content', 7, 'additionalRows', 0, 'data', 0, 'value'], '').replace(/\s/g, '')),
      price: Number(get(data, [0, 'content', 7, 'mainRow', 'data', 0, 'value'], '').replace(/\s/g, '')),
      type: 'pack',
    },
    {
      title: '',
      titleNoTranslate: get(data, [0, 'content', 7, 'mainRow', 'grade', 1, 'description'], ''),
      count: Number(get(data, [0, 'content', 7, 'additionalRows', 0, 'data', 1, 'value'], '').replace(/\s/g, '')),
      price: Number(get(data, [0, 'content', 7, 'mainRow', 'data', 1, 'value'], '').replace(/\s/g, '')),
      type: 'pack',
    },
    {
      title: '',
      titleNoTranslate: get(data, [0, 'content', 7, 'mainRow', 'grade', 2, 'description'], ''),
      count: Number(get(data, [0, 'content', 7, 'additionalRows', 0, 'data', 2, 'value'], '').replace(/\s/g, '')),
      price: Number(get(data, [0, 'content', 7, 'mainRow', 'data', 2, 'value'], '').replace(/\s/g, '')),
      type: 'pack',
    },
    {
      title: '',
      titleNoTranslate: get(data, [0, 'content', 7, 'mainRow', 'grade', 3, 'description'], ''),
      count: Number(get(data, [0, 'content', 7, 'additionalRows', 0, 'data', 3, 'value'], '').replace(/\s/g, '')),
      price: Number(get(data, [0, 'content', 7, 'mainRow', 'data', 3, 'value'], '').replace(/\s/g, '')),
      type: 'pack',
    },
  ],
  statistic: [
    {
      title: 'Ваші авто',
      count: Number(get(data, [0, 'content', 2, 'mainRow', 'value'], '').replace(/\s/g, '')),
    },
    {
      title: 'Перегляди авто',
      count: Number(get(data, [0, 'content', 4, 'mainRow', 'value'], '').replace(/\s/g, '')),
    },
    {
      title: 'Відкриття номерів',
      count: Number(get(data, [0, 'content', 5, 'mainRow', 'value'], '').replace(/\s/g, '')),
    },
    {
      title: 'Ваші чати',
      count: Number(get(data, [0, 'content', 6, 'mainRow', 'value'], '').replace(/\s/g, '')),
    },
  ],
});
