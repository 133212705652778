const translate = {
  messages: {
    uk: {
      'Статистика вашей компании': 'Статистика вашої компанії',
      'Укажите сумму': 'Вкажіть суму',
      'Введите сумму': 'Введіть суму',
      'Укажите количество транспортных средств, которые планируете продавать в ближайший месяц': 'Вкажіть кількість транспортних засобів, які плануєте продавати найближчого місяця',
      'Выгоды на публикации будут доступны после пополнения счета': 'Вигоди на публікації будуть доступні після поповнення рахунку',
      'Пополнить': 'Поповнити',
      'счет': 'рахунок',
      'Рекомендованная сумма': 'Рекомендована сума',
      'дней публикаций': 'днів публікацій',
      'Виды тарифов': 'Види тарифів',
      'Введите количество публикаций': 'Введіть кількість публікацій',
      'Введите': 'Введіть',
      'Всего к оплате': 'Всього до сплати',
      'от': 'від',
      'От': 'Від',
      'лише для сільгосп. техніки': 'лише для сільгосп. техніки',
      'С 22.12. публикация спец- и сельхозтехники стоит 200 грн, не зависимо от ее стоимости': 'З 22.12 публікація спец- та сільгосптехніки коштує 200 грн, не залежно від її вартості',
      'Усього': 'Усього',
      'Ефективність розміщення': 'Ефективність розміщення',
      'Витрати за період': 'Витрати за період',
      'Сервіс': 'Сервіс',
      'Кількість, шт': 'Кількість, шт',
      'Вартість, грн': 'Вартість, грн',
      'Публікації': 'Публікації',
      'ТОПи': 'ТОПи',
      'Статистика': 'Статистика',
      'Ваші авто': 'Ваші авто',
      'Перегляди авто': 'Перегляди авто',
      'Відкриття номерів': 'Відкриття номерів',
      'Ваші чати': 'Ваші чати',
    },
    ru: {
      'Статистика вашей компании': 'Статистика вашей компании',
      'Укажите сумму': 'Укажите сумму',
      'Введите сумму': 'Введите сумму',
      'Укажите количество транспортных средств, которые планируете продавать в ближайший месяц': 'Укажите количество транспортных средств, которые планируете продавать в ближайший месяц',
      'Выгоды на публикации будут доступны после пополнения счета': 'Выгоды на публикации будут доступны после пополнения счета',
      'Пополнить счет': 'Пополнить счет',
      'Рекомендованная сумма': 'Рекомендованная сумма',
      'дней публикаций': 'дней публикаций',
      'Виды тарифов': 'Виды тарифов',
      'Введите количество публикаций': 'Введите количество публикаций',
      'Введите': 'Введите',
      'Всего к оплате': 'Всего к оплате',
      'от': 'от',
      'От': 'От',
      'лише для сільгосп. техніки': 'только для сельхозтехники',
      'С 22.12. публикация спец- и сельхозтехники стоит 200 грн, не зависимо от ее стоимости': 'С 22.12. публикация спец- и сельхозтехники стоит 200 грн, не зависимо от ее стоимости',
      'Усього': 'Всего',
      'Ефективність розміщення': 'Эффективность размещения',
      'Витрати за період': 'Расходы за период',
      'Сервіс': 'Сервис',
      'Кількість, шт': 'Количество, шт',
      'Вартість, грн': 'Стоимость, грн',
      'Публікації': 'Публикации',
      'ТОПи': 'ТОПы',
      'Статистика': 'Статистика',
      'Ваші авто': 'Ваши авто',
      'Перегляди авто': 'Просмотры авто',
      'Відкриття номерів': 'Открытие номеров',
      'Ваші чати': 'Ваши чаты',
    },
  },
};
export default translate;
